import dynamic from 'next/dynamic'

import { handleChunkLoadError } from '@/lib/errorHandler'

/**
 * Application Start Module
 */
export default function App() {
  return <AppRouter />
}

const AppRouter = dynamic(
  () => {
    /**
     * @caveat 型変換エラー解消のために一旦変数に格納. バグと思われる
     */
    const promise = import('../app/router/AppRouter')
      .then(md => md.AppRouter)
      .catch(async error => {
        await handleChunkLoadError(error)
        return () => null
      })
    return promise
  },
  { ssr: false }
)
