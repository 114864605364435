import { getPlatform } from '@/core/native/app'
import { getChunkFileErrors, setChunkFileErrors } from '@/core/native/storage'

export async function handleChunkLoadError(error: Error) {
  if (error.name !== 'ChunkLoadError') return

  const fileChunkName = getFileChunk(error.message)
  if (!fileChunkName) return

  // check file existed in storage
  const fileChunkErrors = await getChunkFileErrors()
  if (fileChunkErrors.findIndex(o => o === fileChunkName) >= 0) {
    return
  }

  if (getPlatform() === 'web') {
    // store file chunk name
    await setChunkFileErrors([...fileChunkErrors, fileChunkName])
    window.location.reload()
    return
  }
  return
}

function getFileChunk(message: string): string {
  const matches = message.match(/(\d*.[\\d\w]*.js)/)
  return matches?.[0] ?? ''
}
